<template>
	<div class="bg-shop-summary">
		<div class="tipBox">
			<p>C用户</p>
			<ul>
				<li>{{ dataInfo.totalMemberNum }}<span>用户总数</span></li>
				<li>{{ dataInfo.todayAddMemberNum }}<span>今日新增用户</span></li>
				<li>{{ dataInfo.yesterdayAddMemberNum }}<span>昨日新增用户</span></li>
				<li>{{ dataInfo.yesterdayPayNum }}<span>昨日付款用户</span></li>
			</ul>
		</div>
		<div class="tipBox">
			<p>订单数据</p>
			<ul>
				<li>{{ dataInfo.todayOrderNum }}<span>下单数</span></li>
				<li>{{ dataInfo.todayPayNum }}<span>支付订单</span></li>
				<li>{{ dataInfo.todayPayMoney }}<span>支付金额</span></li>
				<li>{{ dataInfo.todayRefundNum }}<span>售后订单数</span></li>
			</ul>
		</div>
	</div>
</template>

<script>
	import * as API_buyAnyalysis from '@/api/buyAnyalysis'
	import { CategoryPicker } from '@/components'
	export default {
		name: 'buyAnalysis',
		components: {
			[CategoryPicker.name]: CategoryPicker
		},
		data() {
			return {
				dataInfo: {}
			}
		},
		created() {
			this.GET_DataInfo()
		},
		mounted() {

		},
		methods: {
			GET_DataInfo(){
				API_buyAnyalysis.orderTodayData().then(res => {
					this.dataInfo = res
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tipBox{background: #fff;margin-bottom: 10px;padding: 20px;}
	.tipBox p{margin: 0;line-height: 18px;margin-bottom: 15px;font-size: 14px;}

	.conditions{display: inline-block;margin-right: 20px;}
	.conditions.right{float: right;}
	.conditions >>> .el-input{display: inline-block;}
	.conditions span{font-size: 14px;color: #606266;}

	.btnTab{margin-top: 20px;margin-bottom: -30px;position: relative;z-index: 1;width: 500px;}

	.tipBox ul{border:1px solid #eee;overflow: hidden;list-style: none;padding: 0;margin: 0;margin-top: 20px;}
	.tipBox ul li{width: 25%;float: left;box-sizing: border-box;border-right:1px solid #eee;text-align: center;padding:20px 0;color: #333;font-size: 20px;line-height: 30px;}
	.tipBox ul li span{display: block;color: #999;font-size: 14px;}
	.tipBox ul li:last-child{border-right: 0;}
	.tipBox p{line-height: 24px;padding: 0;position: relative;padding-left: 18px;font-size: 24px;color: #393c41;margin-bottom: 30px;}
	.tipBox p:before{content: ' ';display: block;width: 8px;height: 24px;background: #1a43a9;position: absolute;left: 0;top: 0;}

	.tipBox{background: none;}
	.tipBox ul{border:0;}
	.tipBox ul li{height: 228px;background: #fff;border-radius: 10px;width: 24%;margin-right: 1%;border:0;font-size: 48px;color: #393c41;line-height: 70px;padding-top: 58px;font-weight: 700;}
	.tipBox ul li span{font-size: 24px;color: #393c41;line-height: 30px;font-weight: normal;}

</style>

