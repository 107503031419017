import request from '@/utils/request'

/**
 * 获取购买分析购买时段分布列表
 * @param params
 * @returns {Promise<any>}
 */
export function getbuyAnyalysisPeriodList(params) {
  return request({
    url: 'seller/statistics/reports/purchase/period',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取购买分析客价单分布列表
 * @param params
 * @returns {Promise<any>}
 */
export function getbuyAnyalysisRangesList(params) {
  return request({
    url: 'seller/statistics/reports/purchase/ranges',
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 商家后台-》新增会员统计
 * @param params
 * @returns {Promise<any>}
 */
export function increaseMember(params) {
  return request({
    url: 'seller/statistics/member/increase/member',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》访客占比
 * @param params
 * @returns {Promise<any>}
 */
export function orderVisitRadio(params) {
  return request({
    url: 'seller/statistics/member/order/visitRadio',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》订单数
 * @param params
 * @returns {Promise<any>}
 */
export function orderQuantity(params) {
  return request({
    url: 'seller/statistics/member/order/quantity',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》top订单数
 * @param params
 * @returns {Promise<any>}
 */
export function orderTopNum(params) {
  return request({
    url: 'seller/statistics/member/order/topNum',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》top金额消耗
 * @param params
 * @returns {Promise<any>}
 */
export function orderTopMoney(params) {
  return request({
    url: 'seller/statistics/member/order/topMoney',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》消耗金额
 * @param params
 * @returns {Promise<any>}
 */
export function orderMoney(params) {
  return request({
    url: 'seller/statistics/member/order/money',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》活跃数据统计-》top订单数
 * @param params
 * @returns {Promise<any>}
 */
// export function orderTopNum(params) {
// 	return request({
// 		url: 'seller/statistics/member/order/topNum',
// 		method: 'get',
// 		loading: false,
// 		params
// 	})
// }

/**
 * 商家后台-》今日数据-》今日数据
 * @param params
 * @returns {Promise<any>}
 */
export function orderTodayData(params) {
  return request({
    url: 'seller/statistics/member/order/todayData',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家后台-》日常数据-》日常数据
 * @param params
 * @returns {Promise<any>}
 */
export function orderDayData(params) {
  return request({
    url: 'seller/statistics/member/order/dayData',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 企业后台》数据统计》今日数据
 * @param params
 * @returns {Promise<any>}
 */
export function enterpriseTodayData(params) {
  return request({
    url: 'seller/statistics/enterprise/todayData',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 企业后台》数据统计》营收数据
 * @param params
 * @returns {Promise<any>}
 */
export function enterpriseMoney(params) {
  return request({
    url: 'seller/statistics/enterprise/money',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 企业后台》数据统计》营收数据》每日/月数据统计
 * @param params
 * @returns {Promise<any>}
 */
export function getTurnoverMonthData(params) {
  return request({
    url: 'seller/statistics/enterprise/getTurnoverMonthData',
    method: 'get',
    loading: false,
    params
  })
}


/**
 * 供应商后台-》数据统计-》营收数据-》新增
 * @param params
 * @returns {Promise<any>}
 */
export function orderNewMoney(params) {
  return request({
    url: 'seller/statistics/member/order/newMoney',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商后台-》数据统计-》营收数据-》累计
 * @param params
 * @returns {Promise<any>}
 */
export function orderAccMoney(params) {
  return request({
    url: 'seller/statistics/member/order/accMoney',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商后台-》数据统计-》营收数据-》每日(月)营收统计
 * @param params
 * @returns {Promise<any>}
 */
export function orderTurnover(params) {
  return request({
    url: 'seller/statistics/member/order/turnover',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商统计=》数据统计=》统计=》订单数据
 * @param params
 * @returns {Promise<any>}
 */
export function orderNum(params) {
  return request({
    url: 'seller/statistics/order/num',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商统计=》数据统计=》统计=》每日每月订单数据
 * @param params
 * @returns {Promise<any>}
 */
export function orderNumList(params) {
  return request({
    url: 'seller/statistics/order/numList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商》统计数据》商品数据》
 * @param params
 * @returns {Promise<any>}
 */
export function goodsGoodsNum(params) {
  return request({
    url: 'seller/statistics/goods/goodsNum',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商》统计数据》商品数据》每日/月商品统计
 * @param params
 * @returns {Promise<any>}
 */
export function goodsNumByCycleType(params) {
  return request({
    url: 'seller/statistics/goods/goodsNumByCycleType',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取店铺uv访问量数据
 * @param params
 * @returns {Promise<any>}
 */
export function page_viewShopUV(params) {
  return request({
    url: '/seller/statistics/page_view/shopUV',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取店铺访问量数据
 * @param params
 * @returns {Promise<any>}
 */
export function page_viewShopPV(params) {
  return request({
    url: 'seller/statistics/page_view/shopPV',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取商品pv访问量数据，只取前5
 * @param params
 * @returns {Promise<any>}
 */
export function page_viewGoodsPV(params) {
  return request({
    url: 'seller/statistics/page_view/goodsPV',
    method: 'get',
    loading: false,
    params
  })
}

















